import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { delay, finalize, Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    private totalRequests = 0;

    constructor(private service: SpinnerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        this.totalRequests++;

        if (!req.headers.get('skipLoader')) this.service.show();

        return next.handle(req).pipe(
            delay(50),
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests === 0) this.service.hide();
            }));
    }
}
