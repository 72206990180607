import { Injectable, inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, throwError, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { removeToken } from 'src/app/core/shared/util/token';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(private translate: TranslateService, private router: Router, private jwtHelper: JwtHelperService, private location: Location) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('token');
    const lang = this.translate.currentLang || 'ar';
    const currentPath = this.location.path(); // Get the current path
    const translate: TranslateService = inject(TranslateService);
    const toast: ToastrService = inject(ToastrService)

    let newReq = req.clone({
      headers: req.headers.set('Accept-Language', lang).set('Strict-Transport-Security', 'max-age=1000000000000; includeSubDomains')
    });


    if (token && !req.headers.get('skip')) {
      newReq = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + token)
          .set('Accept-Language', lang)
          .set('Strict-Transport-Security', 'max-age=1000000000000; includeSubDomains')
      });

      // //reset idle Timer
      // this.idleService.resetIdleTimer();

    }

    return next.handle(newReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.ok === false && !req.headers.get('skipError')) {

            Swal.fire({
              icon: 'warning',
              showConfirmButton: false,
              text: event.body.message,
            });
          }
        }
        return event;
      }),
      catchError((error: any) => {
        console.log('error', error.status);
        if (req.url.includes('check-nafath-done') || currentPath.includes('verify-nafath')) {
          return throwError(error);
        }
        if (error.status === 0) {
          removeToken();
          toast.warning(translate.instant('ERROR.SESSION_END'));
          this.router.navigate(['auth/login']);
        } else if (error.status.toString().startsWith('4')) {
          Swal.fire({
            icon: 'warning',
            text: error.error.Message,
            confirmButtonColor: "#FACEA8",
            confirmButtonText: "حسنا",
          });
        } else {
          Swal.fire({
            icon: 'error',
            text: "حدث خطأ الرجاء المحاولة مرة أخرى",
            confirmButtonColor: "#f27474",
            confirmButtonText: "حسنا",
          });
        }
        return throwError(error);
      })
    );
  }





}
