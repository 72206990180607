import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '', redirectTo: '/auth/login', pathMatch: 'full'
  },

  // auth
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  // layout
  {
    path: '',
    loadChildren: () => import('./layout/modules/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, { scrollPositionRestoration: 'top' }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }