import { Component, HostListener, NgModule, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScreenSizeSignals } from './core/shared/signals/screen-size.signals';
import { ToastrModule } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'wadaie-frontend';

  constructor(public translate: TranslateService, private screen: ScreenSizeSignals) {
    translate.addLangs(['ar', 'en']);
    translate.use('ar');

  }

  ngOnInit(): void {
    this.onLangChange();
    this.onWindowResize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screen.screen.set(window.innerWidth)
  }
  @NgModule({
    imports: [
      ToastrModule.forRoot({
        positionClass: 'toast-center',
        preventDuplicates: true,
      }),
    ],
  })

  onLangChange() {
    this.translate.onLangChange.subscribe(lang => {
      document.getElementsByTagName('body')[0].dir = (lang.lang === 'ar') ? 'rtl' : 'ltr';
    })
  }
}
