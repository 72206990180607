import { NgModule } from "@angular/core";
import { ExportedComponents } from "../components/exported-components";
import { ExportedDirectives } from "../directives/exported-directives";
import { ExportedPipes } from "../pipes/exported-pipes";
import { ExportedModules } from "./exported-modules";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "src/app/auth/interceptor/auth.interceptor";


@NgModule({
    declarations: [
        ...ExportedComponents,
        ...ExportedDirectives,
        ...ExportedPipes
    ],
    imports:[
        ...ExportedModules
    ],
    exports: [
        ...ExportedModules,
        ...ExportedComponents,
        ...ExportedDirectives,
        ...ExportedPipes
    ],
    providers: [
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi   : true
        }        
    ]
})

export class SharedModule { }